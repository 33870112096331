<template>
    <section class="bg-white mt-4">
        <tabla-general :data="dataTable" alto="calc(100vh - 365px)" size="large" class-header="text-general f-16" @filaFuncion="openDetalle">
            <template slot="cabecera-izquierda">
                <div class="col-auto px-4 py-3 text-general f-18 f-600">
                    {{ dataTable.length }} pedidos pendientes por comisión
                </div>
            </template>
            <el-table-column label="Fecha de pago" align="center">
                <template slot-scope="{row}">
                    <p class="text-general f-16 fr-light">{{ row.created_at | helper-fecha('DD MMM Y') }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Valor pagado" align="center">
                <template slot-scope="{row}">
                    <p class="text-general f-16 fr-light">{{ separadorNumero(row.valor_pagado) }}</p>
                </template>
            </el-table-column>
            <el-table-column prop="pedidos_pagados" label="Pedidos pagados" align="center">
                <template slot-scope="{row}">
                    <p class="text-general f-16 fr-light">{{ row.cant_pedidos }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Encargado" align="center">
                <template slot-scope="{row}">
                    <p class="text-general f-16 fr-light">{{ row.encargado }}</p>
                </template>
            </el-table-column>
        </tabla-general>
		<el-pagination class="paginar-general d-middle-center" layout="prev, pager, next" :total="paginate.total" :page-size="paginate.per_page" @current-change="getTabla" />
        <modal-ver-detalle-comision ref="refModalVerDetalleComision" :tendero="tendero" :encargado="encargado" />
    </section>
</template>
<script>
import modalVerDetalleComision from '../../pages/tesoreria/liquidacionComisiones/partials/modalDetalleComision.vue'
import Comisiones from '~/services/comisiones/comisiones_pagadas';
export default {
    name: 'ComisionesLlantasPagadasCli',
    components: {
        modalVerDetalleComision,
    },
    data(){
        return{
            dataTable:[],
			paginate: {},
			tendero: {},
			encargado: {},
        }
    },
	mounted(){
        this.getTabla(1)
    },
    methods: {
        openDetalle(row){
			console.log(row);
			this.encargado.nombre = row.encargado
			this.encargado.fecha = row.created_at
			this.encargado.img = row.img
			this.tendero = {
				nombre:this.$usuario.tienda.propietario_nombre,
				img:this.$usuario.tienda.logo,
				total: row.valor_pagado
			}
			console.log(this.tendero);
            this.$refs.refModalVerDetalleComision.toggle(row.id, this.$usuario.tienda.id)
        },
		async getTabla(page){
            try {
                const {data} = await Comisiones.getComisionesFinalizadasTendero({idTienda: this.$usuario.tienda.id, page})
                this.dataTable = data.data.data
				this.paginate = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>
<style lang="scss">
</style>